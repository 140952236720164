'use client';
import React, { createContext, useContext, useState, useEffect, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

type NavigationBarContent = React.ReactNode;

type NavigationContextType = {
  setNavigationBarContent: (content: NavigationBarContent) => void;
  portalElement: HTMLElement | null;
  setPortalElement: (element: HTMLElement) => void;
};

const NavigationContext = createContext<NavigationContextType>({
  setNavigationBarContent: () => { },
  portalElement: null,
  setPortalElement: () => { },
});

export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [navigationBarContent, setNavigationBarContent] = useState<NavigationBarContent>(null);
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

  return (
    <NavigationContext.Provider value={{ setNavigationBarContent, portalElement, setPortalElement }}>
      {children}
      <GlobalNavigationBar content={navigationBarContent} />
    </NavigationContext.Provider>
  );
};


type GlobalNavigationBarProps = {
  content: React.ReactNode;
}

export function GlobalNavigationBar({ content }: GlobalNavigationBarProps): React.ReactPortal | null {
  const { portalElement } = useNavigationContext();

  if (!portalElement || !content) return null;

  return createPortal(
    <div className="flex items-center justify-between gap-4 px-4 py-2 overflow-hidden border-t border-gray-900 bg-gray-950">
      {content}
    </div>,
    portalElement,
  );
}

