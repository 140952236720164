import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/immutable-frontend/immutable-frontend/app/layout-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/immutable-frontend/immutable-frontend/components/tutorial/first.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/immutable-frontend/immutable-frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/immutable-frontend/immutable-frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/immutable-frontend/immutable-frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/immutable-frontend/immutable-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/immutable-frontend/immutable-frontend/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"fontInter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/immutable-frontend/immutable-frontend/providers/providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/immutable-frontend/immutable-frontend/styles/app.scss");
